export default function () {
    return {
        init: {
            ['@private-data-loaded.window']: function($event) {
                const data = $event.detail.data;
                if (!data.wpp) {
                    return;
                }
                this['wpp'] = data.wpp;
            }
        },
        showCart: false,
        subtotal: 0,
        wpp: {
            is_wpp: false,
            points: 0,
            current_benefits: [],
            next_point_step: 0,
            next_benefits: [],
            new_points: 0
        },
    }
}
