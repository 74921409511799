import init from '@frontend/init';
import Cart from '@frontend/components/Cart';
import Search from '@frontend/components/Search';
import Messages from '@frontend/components/Messages';
import Filters from '@frontend/components/Filters';
import Price from '@frontend/components/Price';
import Account from '@frontend/components/Account';
import WPP from '@frontend/components/WPP';
import RegionData from '@frontend/components/RegionData';

import "@frontend/utilities/delayed-scripts";
import { initPrivateData } from "@frontend/utilities/private-data";
import lazyload from "@frontend/utilities/lazyload";
import "@frontend/utilities/form-key";
import "@frontend/utilities/varienform";
import "@frontend/utilities/translate";
import "@frontend/utilities/validation/standard-validations";
import "@frontend/utilities/region-updater";
import "@frontend/utilities/data-post";

init({
    Cart,
    Search,
    Messages,
    Filters,
    Price,
    WPP,
    Account,
    RegionData
});
initPrivateData();
lazyload.update();
